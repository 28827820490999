<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro05 from '@/components/shared/relatorio/filtros/Filtro05.vue'

export default {
  extends: Filtro05,

  data() {
    return {
      modulo: 'consignataria',
    }
  },

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarListaConsignatarias() {
      if (this.$auth.hasRoleGestao()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res
          if (this.store.consignatariaSelecionada !== null) {
            this.consignatariasSelecionadas = res.filter((item) => {
              return item.id === this.store.consignatariaSelecionada.id
            })
          }
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res
            if (this.store.consignatariaSelecionada !== null) {
              this.consignatariasSelecionadas = res.filter((item) => {
                return item.id === this.store.consignatariaSelecionada.id
              })
            }
          })
      }
    },
  },
}
</script>
